mat-checkbox.mat-mdc-checkbox {
  display: flex;

  .mdc-checkbox__background {
    border-radius: 4px;
  }

  .mdc-form-field {
    white-space: normal;
  }

  .mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: var(--font-white);
    --mdc-checkbox-unselected-icon-color: var(--gray-6);
    // --mdc-checkbox-unselected-pressed-icon-color: #var(--gray-8);
    --mdc-checkbox-unselected-hover-icon-color: var(--gray-5);
    --mdc-checkbox-unselected-focus-icon-color: var(--gray-5);
  }

  .mat-mdc-checkbox {
    color: var($--primary-1);
  }
}