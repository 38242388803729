@import "src/styles/mixins";

input.mat-mdc-input-element {
  @include forms-input;
}

label {
  @include forms-label;
  color: var($--font-2);
  &.label-with-margin {
    margin-bottom: 10px;
  }
  &.label-without-margin {
    margin-bottom: 0;
  }

  text-wrap: wrap;
  word-break: break-all;
}

.mat-mdc-form-field-error {
  @include body4;
  a {
    @include accent-primary-1;
  }
}
mat-error.mat-mdc-form-field-error {
  color: #f44336;
  display: flex;
  flex-direction: column;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label .mat-mdc-form-field-required-marker {
  display: none;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-subscript-wrapper {
  position: relative!important;
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-loading {
  label {
    height: var(--mat-form-field-container-height);
    
    .loading-placeholder{
      display: flex;
      column-gap: 10px;
      // padding-left: 17px;
      height: var(--mat-form-field-container-height);
      align-items: center;
      @include forms-input;
    }
  }
}

.mdc-text-field--outlined {
  --mat-form-field-container-height: 52px;
  --mdc-outlined-text-field-container-shape: 8px;
  --mdc-outlined-text-field-outline-color: var(--gray-7);
}

.mdc-text-field--outlined:has(mat-select) {
  height: var(--mat-form-field-container-height) !important; // dropdown wasn't getting --mat-form-field-container-height
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;

  .mat-mdc-form-field-bottom-align::before {
    height: 4px; // mat-error is being pushed down
  }
}

.mat-mdc-form-field-subscript-wrapper:has(mat-error) {
  margin-bottom: 1.34375em;
}

.mat-mdc-form-field {
  &.without-bottom-padding,
  &.mat-form-field-no-wrap {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .ng-select {
      padding-bottom: 0;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-subscript-wrapper {
      margin-top: 0;
    }
  }
}

// redefine material forms
mat-form-field.mat-mdc-form-field {
  color: var($--font-2);
  --mat-form-field-container-text-line-height: 1em;

  &.mat-form-field-disabled {
    .mdc-text-field--outlined .mdc-notched-outline {
      z-index: 0;
      .mat-mdc-notch-piece {
        background-color: var($--gray-8);
      }
    }
  }
  &.mat-focused {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    input,
    .mat-form-field-prefix,
    .mat-form-field-infix + .mat-form-field-suffix {
      border-color: var($--primary-1);
    }
  }
  &.ng-touched {
    &.ng-valid {
      /* Change the border color */
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: var($--accent-success-1);
      }
    }
    &.ng-invalid {
      /* Change the border color */
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: var($--accent-danger-1);
      }
    }
  }
  &.mat-form-field-transparent {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
      background-color: transparent;
    }
    input, textarea {
      background-color: transparent;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix + .mat-form-field-suffix
    {
      background-color: transparent;
    }
    &.mat-form-field-has-suffix {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix input
      {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  &.without-bottom-padding,
  &.mat-form-field-no-wrap {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
  &.mat-form-field-appearance-standard {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      padding-top: 0;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      display: none;
    }
  }
  &.mat-form-field-size-small {
    input, textarea {
      padding: 9px 10px;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    align-items: flex-end;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    //row-gap: 10px;
  }

  &.mat-form-field-filled {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
      background-color: var($--background-3);
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix + .mat-form-field-infix {
      input {
        background-color: var($--background-3);
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    line-height: 16px;
    padding: 0 0 0 10px;
    height: 52px;
    margin-right: 0;

    border-top: 1px solid var($--gray-7);
    border-left: 1px solid var($--gray-7);
    border-bottom: 1px solid var($--gray-7);

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    background-color: var($--background-4);
    .mat-icon {
      padding: 0;
      line-height: 1;
      font-size: 100%;
      max-height: 24px;
      max-width: 24px;
      align-self: center;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix + .mat-form-field-infix {
    input {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 10px;
    }
  }
  &.mat-form-field-datepicker,
  &.mat-form-field-button-suffix {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix + .mat-form-field-suffix {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 10px 0 0;
      height: 52px;
      box-sizing: border-box;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix + .mat-form-field-suffix {
    display: flex;
    box-sizing: border-box;

    line-height: 16px;
    padding: 0 17px 0 0;
    height: 52px;
    margin-left: -17px;

    border-top: 1px solid var($--gray-7);
    border-right: 1px solid var($--gray-7);
    border-bottom: 1px solid var($--gray-7);

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    //background-color: inherit;
    background-color: var($--background-4);
    .mat-icon {
      padding: 0;
      line-height: 1;
      font-size: 100%;
      max-height: 24px;
      max-width: 24px;
      align-self: center;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-subscript-wrapper {
    position: relative;
  }
}

mat-form-field {
  padding-top: 10px; // to make gap with label

  &.without-top-padding {
    padding-top: 0;
  }
}

.label-and-mat-form-field {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
