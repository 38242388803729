@import "src/styles/mixins";

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-handle-color: var(--primary-700);
  --mdc-switch-selected-hover-handle-color: var(--primary-700);
  --mdc-switch-selected-pressed-handle-color: var(--primary-700);
  --mdc-switch-selected-focus-track-color: var(--primary-400);
  --mdc-switch-selected-hover-track-color: var(--primary-400);
  --mdc-switch-selected-pressed-track-color: var(--primary-400);
  --mdc-switch-selected-track-color: var(--primary-400);

}