@import "../colors";
.pqLightbox {
  lightbox {
    border-radius: 20px;
  }
  gallery {
    background-color: var($--background-2);
  }
}

@media screen and (max-width: 840px) {
  .pqLightbox {
    lightbox {
      border-radius: 0;
      max-width: 100vw;
      max-height: 100vh;
    }
  }
}

