@import "./../mixins";
.pq-badge {
  @include body4-medium;
  padding: 0.3em 1em;
  border-radius: 5px;
  text-align: center;
}
.pq-badge-flat {
  @include body4-medium;
  padding: 0.3em 1em;
  border-radius: 5px;
  text-align: center;
}

