@import "src/styles/mixins";

// hover
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  font-weight: 600;
  --mat-datepicker-calendar-date-text-color: var(--font-white);
}

.mat-calendar-body-selected {
  font-weight: 600;
  --mat-datepicker-calendar-date-selected-state-text-color: var(--font-white);
}

.mat-calendar-body-active .mat-calendar-body-today,
.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  --mat-datepicker-calendar-date-focus-state-background-color: var(--font-white);
}
