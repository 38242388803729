@import "./../mixins";

.mat-badge-accent {
  .mat-badge-content {
    background-color: var($--secondary-1);
    color: var($--font-white);
  }
}

.mat-badge-small,
.mat-badge-medium{
  .mat-badge-content {
    border: 2px solid var($--background-4);
  }
}