@use '@angular/material' as mat;
@use 'components/badge-theme' as pqBadge;
@import "colors";
@import "fonts";

//$__primary: #00294A;
//$__secondary: #E7C410;
$__primary: #008D97;
$__secondary: #FFCE73;
//$__primary: #DA5025;
//$__secondary: #FFCE73;

// all existing css variables mapped in :root
$theme-main: (
        $--primary-1: $__primary,
        $--primary-2: darken($__primary, 7%),
        $--primary-3: #006D7B,
        $--primary-4: #1FB4BF,
        $--primary-5: #CBEDEF,
        $--primary-6: #F6FBFC,

        $--primary-t-7: rgba($__primary, 0.7),

        $--primary-50: lighten($__primary, 80%),
        $--primary-100: lighten($__primary, 60%),
        $--primary-200: lighten($__primary, 50%),
        $--primary-300: lighten($__primary, 40%),
        $--primary-400: lighten($__primary, 30%),
        $--primary-500: lighten($__primary, 20%),
        $--primary-600: lighten($__primary, 10%),
        $--primary-700: var($--primary-1),
        $--primary-800: darken($__primary, 7%),
        $--primary-900: darken($__primary, 14%),

        $--secondary-1: $__secondary,
        $--secondary-2: darken($__secondary, 7%),
        $--secondary-3: #FF9A7B,
        $--secondary-4: #FFE2AB,
        $--secondary-5: #FFF3DC,

        $--secondary-50: lighten($__secondary, 80%),
        $--secondary-100: lighten($__secondary, 60%),
        $--secondary-200: lighten($__secondary, 50%),
        $--secondary-300: lighten($__secondary, 40%),
        $--secondary-400: lighten($__secondary, 30%),
        $--secondary-500: lighten($__secondary, 20%),
        $--secondary-600: lighten($__secondary, 10%),
        $--secondary-700: var($--secondary-1),
        $--secondary-800: darken($__secondary, 7%),
        $--secondary-900: darken($__secondary, 14%),

        $--gray-1: #090B0C,
        $--gray-2: #2F353B,
        $--gray-3: #555F6A,
        $--gray-4: #7D8997,
        $--gray-5: #959FAA,
        $--gray-6: #ADB4BD,
        $--gray-7: #DCDFE3,
        $--gray-8: #F4F4F4,
        $--gray-9: #FAFAFA,

        $--accent-success-1: #21B17D,
        $--accent-success-2: #00834F,
        $--accent-success-3: #8BD0B0,
        $--accent-success-4: #E2F4EC,
        $--accent-warning-1: #FFB443,
        $--accent-warning-2: #FF9A3D,
        $--accent-warning-3: #F6C378,
        $--accent-warning-4: #FFFBF2,
        $--accent-warning-5: #fff9e5,

        $--accent-danger-1: #FF2B2B,
        $--accent-danger-2: #D30808,
        $--accent-danger-3: #FF9C9C,
        $--accent-danger-4: #FEF6F6,

        $--accent-info-1: #276EF1,
        $--accent-info-2: #0A3E9E,
        $--accent-info-3: #9ABBF8,
        $--accent-info-4: #F5FAFF,

        $--accent-attention-1: #8F1AE8,
        $--accent-attention-2: #7311BD,
        $--accent-attention-3: #B370F1,
        $--accent-attention-4: #F3E7FC,

        $--font-1: #2F353B,
        $--font-2: #323B4B,
        $--font-3: #4E5D78,
        $--font-4: #8A94A6,
        $--font-5: #B0B7C3,

        $--font-white: #FFFFFF,

        $--background-1: #C1C7D0,
        $--background-2: #F3F3F3,
        $--background-3: #FAFBFC,
        $--background-4: #FFFFFF,
        $--background-5: #F2F5F7,
);

$primary-palette: (
        50: var($--primary-50),
        100: var($--primary-100),
        200: var($--primary-200),
        300: var($--primary-300),
        400: var($--primary-400),
        500: var($--primary-500),
        600: var($--primary-600),
        700: var($--primary-700),
        800: var($--primary-800),
        900: var($--primary-900),
        //50: #f9e9e7,
        //100: #faccbe,
        //200: #f7ab94,
        //300: #f58b6a,
        //400: #f4734b,
        //500: #f35c2d,
        //600: #e8562a,
        //700: #da4f25,
        //800: #cc4922,
        //900: #521908,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: rgba(black, 0.87),
                600: rgba(black, 1),
                700: rgba(white, 1),
                800: rgba(white, 1),
                900: rgba(white, 1),
        )
);
$secondary-palette: (
        50: var($--secondary-50),
        100: var($--secondary-100),
        200: var($--secondary-200),
        300: var($--secondary-300),
        400: var($--secondary-400),
        500: var($--secondary-500),
        600: var($--secondary-600),
        700: var($--secondary-700),
        800: var($--secondary-800),
        900: var($--secondary-900),
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: rgba(black, 0.87),
                600: rgba(black, 0.87),
                700: rgba(black, 0.87),
                800: rgba(black, 0.87),
                900: rgba(black, 0.87),
        )
);

$my-primary: mat.m2-define-palette($primary-palette, 700);
$my-accent: mat.m2-define-palette($secondary-palette, 700);

$pq-success: mat.m2-define-palette($success-palette, 400);
$pq-warning: mat.m2-define-palette($warning-palette, 700);
$pq-danger: mat.m2-define-palette($danger-palette, 700);
$pq-info: mat.m2-define-palette($info-palette, 700);

@include mat.core();

@include mat.all-component-typographies($custom-typography-config);
@include mat.sidenav-typography($toolbar-typography-config);
@include mat.toolbar-typography($toolbar-typography-config);
@include mat.table-typography($table-typography-config);



$my-theme: mat.m2-define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        )
));
@include mat.all-component-themes($my-theme);
@include pqBadge.theme($success-palette, $warning-palette, $danger-palette, $info-palette);

:root {
        @include spread-map($theme-main);
}

