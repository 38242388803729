@import "src/styles/mixins";

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select.mat-mdc-select {
  display: flex;
  align-items: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
  transform: none;
}


.mat-select-badge {
  .mat-mdc-form-field-icon-prefix {
    padding-left: 16px;
  }

  .mat-mdc-select-trigger {
    height: 16px;
  }

  .mdc-text-field--outlined {
    --mat-form-field-container-height: 46px;

    .mdc-notched-outline {
      z-index: 0;
      .mat-mdc-notch-piece {
        background-color: var($--primary-5);
        border: 0;
      }
    }
  }

  mat-select.mat-mdc-select {
    padding: 0 11px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 0;
    @include h9-bold;
    @include accent-primary-1;

    .mat-mdc-select-arrow {
      --mat-select-enabled-arrow-color: var($--primary-700);
    }

    .mat-mdc-select-value {
      @include accent-primary-1;
    }
  }
}
