@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import "@ng-select/ng-select/themes/material.theme.css";
@import '../../node_modules/angular-calendar/css/angular-calendar.css';

@import 'ngx-toastr/toastr';
@import "components/toastr";

@import "mixins";

@import "components/ng-select";
@import "components/badge";
@import "components/checkbox-list";
@import 'components/quill-ngx-dropzone';
@import "components/lightbox";
@import "components/mat-button";
@import "components/mat-card";
@import "components/mat-checkbox";
@import "components/mat-dialog";
@import "components/mat-input";
@import "components/mat-select";
@import "components/mat-slide-toggle";
@import "components/mat-slider";
@import "components/mat-badge";
@import "components/mat-menu";
@import "components/mat-calendar";

@import "platforms";

body {
  height: calc(100vh - 86px);
  background-color: var($--background-5);
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none;   /* Webkit */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Edge*/
  user-select: none;       /* Future-proof*/
}
span, label {
  -webkit-touch-callout: none !important; /* Safari Touch */
  -webkit-user-select: none!important;   /* Webkit */
  -moz-user-select: none!important;      /* Firefox */
  -ms-user-select: none!important;       /* Edge*/
  user-select: none!important;       /* Future-proof*/
}

//scrollbar
::-webkit-scrollbar {
  //margin: 0 7px;
  width: 19px;
}
::-webkit-scrollbar-button {
   display: none;
}
::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border: 6.5px solid transparent;
  border-radius: 15px;
  background-clip: content-box;
}
::-webkit-scrollbar-track {
  background: transparent;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @include accent-primary-1;
}

.one-row {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  row-gap: 1em;
  align-items: center;
  flex-wrap: wrap;
  > .mat-mdc-form-field {
    flex-grow: 1;
  }

  > .margin-bottom {
    flex-grow: 1;
  }

  &.one-row-space-around {
    justify-content: space-between;
  }
  &.one-row-center {
    justify-content: center;
  }
  &.one-row-flex-end {
    justify-content: flex-end;
  }
  &.one-row-flex-start {
    justify-content: flex-start;
  }
  &.one-row-small-gap {
    column-gap: 1em;
  }
  &.one-row-tiny-gap {
    column-gap: 0.5em;
  }
  &.one-row-no-gap {
    column-gap: 0;
  }
  &.one-row-2col-same-size {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    align-items: baseline;
  }
  &.one-row-stop-break {
    flex-wrap: nowrap;
  }
  &.one-row-three-column {
    display: grid;
    grid-template-columns: repeat(3, minmax(55px, 1fr));
  }
  &.one-row-separate-label {
    row-gap: 10px;
  }
  &.one-row-button-group-container {
    row-gap: 0;
  }
  &.one-row-align-top {
    align-items: flex-start;
  }
  &.one-row-no-padding {
    padding: 0;
  }
  &.one-row-subscript-wrapper {
    margin-top: 10px;
  }
  &.one-row-baseline {
    align-items: baseline;
  }
  &.one-row-self-center {
    align-self: center;
  }
}

.full-width-field {
  width: 100%;
}

.text-align-center {
  text-align: center;
}

.button-danger {
  color: var($--accent-danger-1);
}

.list-base {
  display: grid;
  grid-row-gap: 18px;
  &.list-space-around {
    justify-content: space-between;
  }

  &.list-two-columns {
    grid-template-columns: 1fr 1fr;
    :nth-child(2n) {
      text-align: right;
    }
    .mat-divider {
      grid-column-end: 3;
    }
  }
  .mat-divider {
    grid-column-start: 1;
  }
  .mat-divider ~ div:nth-child(n+1) {
    text-align: left;
  }
  .mat-divider ~ div:nth-child(2n+1) {
    text-align: right;
  }
}

.list-table {
  display: grid;
  width: 100%;
  @include body3;
  @include accent-font-2;
  &.list-table-skip-cell-left-padding {
    .cell {
      padding-left: 0;
    }
  }
  .cell {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var($--gray-8);
  }
  .header {
    @include h9-bold;
    @include accent-primary-1;
    background-color: var($--background-3);
  }
  &.col2 {
    grid-template-columns: repeat(2, 1fr);
  }
}


form, .pq-form{
  &.inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  // Mat button

  .mat-primary{
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mdc-form-field {
      color: var($--font-2);
    }
  }
}

// own list checkbox component, payments form
.checkbox-list-table {
  display: grid;
  grid-template-columns: 1fr max-content 62px;
  grid-template-rows: max-content;
  column-gap: 28px;
  row-gap: 14px;
  align-items: center;
  .mat-divider {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  mat-form-field.mat-mdc-form-field {
    padding: 0;
    max-height: 28px;
    --mat-form-field-container-vertical-padding: 5px;

    .mat-mdc-text-field-wrapper {
      max-height: 28px;
      width: 68px;

      .mat-mdc-form-field-flex {
        width: 42px;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      bottom: 12px;
    }

    input {
      min-width: 32px;
    }
  }

  .price {
    text-align: end;
  }

}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.main-container {
  width: 100%;
  align-self: start;
  .router-container {
    max-width: calc(1160px);
    padding: 0 16px;
    margin: 0 auto;
  }
  //.mat-card {
  //  padding: 30px;
  //  border-radius: 20px;
  //}
}

.mat-mdc-tab-group{
  .mat-mdc-tab-header{
    border-bottom: 1px solid var($--gray-8);
  }
  .mat-ink-bar {
    height: 3px;
    border-radius: 6px 6px 0px 0px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    &.mat-tab-label-active {
      opacity: 1;
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label-content {
        @include accent-primary-1;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-wrapper{
    padding-top: 40px;
  }
}
.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid var($--gray-7);
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-link-container {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-list {
    .mat-mdc-tab-links {
      .mat-mdc-tab-link {
        @include h8;
        padding: 0 20px;
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        &.mat-tab-label-active {
          opacity: 1;
          @include accent-primary-1;
        }
      }
    }
    .mat-ink-bar {
      height: 3px;
      border-radius: 6px 6px 0px 0px;
    }
  }
}

// material tabs
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-header-pagination {
  display: none !important;
}
.mat-mdc-tab-header {
  overflow-x: scroll !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label-container {
  overflow: visible !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-link-container {
  overflow: visible !important;
}
.mat-mdc-tab-header::-webkit-scrollbar {
  display: none;
}

// material button-toggle
.mat-button-toggle-group {
  border: 1px solid var($--gray-7);
  background-color: transparent;

  &.mat-button-toggle-group-appearance-standard {
    border-radius: 8px;
    box-sizing: border-box;
  }
  &.mat-button-toggle-group-primary {
    --mat-standard-button-toggle-divider-color: var(--primary-5);

    &.ng-invalid.ng-touched {
      border-color: var($--accent-danger-1);
    }
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-1);
    }
    .mat-button-toggle-appearance-standard {
      background-color: var($--primary-6);
      &.mat-button-toggle-checked {
        background-color: var($--primary-5);
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-focus-overlay {
          border-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        background-color: var($--primary-1);
      }
    }
  }
  &.mat-button-toggle-group-flat-primary {
    --mat-standard-button-toggle-divider-color: var(--primary-1);

    &.ng-invalid.ng-touched {
      border-color: var($--accent-danger-1);
    }
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-2);
    }
    .mat-button-toggle-appearance-standard {
      background-color: var($--primary-6);
      &.mat-button-toggle-checked {
        background-color: var($--primary-1);
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-focus-overlay {
          border-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        background-color: var($--primary-1);
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &.mat-button-toggle-group-stroked {
    border: 1px solid var($--gray-7);
    background-color: transparent;
  }
  &.mat-button-toggle-group-stroked-primary {
    border: 0;
    &.ng-invalid.ng-touched {
      border: 1px solid var($--accent-danger-1)
    }
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-2);
    }

    .mat-button-toggle {
      border-top: 1px solid var($--gray-7);
      border-right: 0;
      border-bottom: 1px solid var($--gray-7);
      border-left: 1px solid var($--gray-7);
      &:first-child {
        // radius on left
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-right: 1px solid var($--gray-7);
        // radius on right
        border-radius: 0 8px 8px 0;
      }
    }
    .mat-button-toggle + .mat-button-toggle {
      border-left: 1px solid var($--gray-7);
    }
    .mat-button-toggle-appearance-standard {
      background-color: var($--background-4);
      &.mat-button-toggle-checked {
        border: 1px solid var($--primary-1) !important;
        background-color: var($--primary-6);
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-focus-overlay {
          border-bottom: 0;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        background-color: var($--primary-1);
      }
    }
  }

  .mat-button-toggle-button {
    @include body4-medium;
    @include accent-font-2;
  }

  .mat-button-toggle {
    width: 100%;
  }
}

// material button toggle mobile veiw - align buttons in a column
@media (max-width: 600px) {
  mat-button-toggle-group.mat-button-toggle-group {
    flex-direction: column;

    mat-button-toggle {
      flex: 1 0 100%;
    }

    mat-button-toggle:not(:last-child) {
      border-bottom: 1px solid var(--mat-standard-button-toggle-divider-color);
    }
  }

  mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-stroked-primary {
    .mat-button-toggle {
      border-top: 1px solid var($--gray-7);
      border-right: 1px solid var($--gray-7);
      border-bottom: 0;
      border-left: 1px solid var($--gray-7);
      &:first-child {
        // radius on top
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-bottom: 1px solid var($--gray-7);
        // radius on bottom
        border-radius: 0 0 8px 8px;
      }
    }
    .mat-button-toggle + .mat-button-toggle {
      border-left: 1px solid var($--gray-7);
    }
  }
}


.mat-primary {
  color: var($--primary-1);
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.mat-drawer-container {
  background-color: var($--background-4);
}

.mat-sidenav {
  width: 270px;
  &.sidenav-container-profile {
    .sidenav-container {
      grid-template-rows: max-content max-content 1fr max-content;
    }
  }
  .sidenav-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .mat-mdc-list-item{
      height: 60px;
    }
    .sidenav-footer {
      padding: 20px;
      .logo {
        max-width: 120px;
        max-height: 30px;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.mat-sidenav-container{
  position: unset;
  margin-top: 86px;
  height: 100%;
  .mat-sidenav-content{
    padding-top: 33px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr max-content;
    align-items: center;
    background-color: var($--background-3);
    row-gap: 33px;
    box-sizing: border-box;
    &.mat-sidenav-content-white {
      background-color: var($--background-4);
    }
  }
  app-footer {

  }
}

.account-sidebar {
  .sidenav-header {
    flex-direction: column;
    justify-items: center;
    padding: 20px 0;
    .avatar {
      margin-bottom: 16px;
      max-width: 56px;
      max-height: 56px;
      min-width: 56px;
      border-radius: 28px;
    }
    .user-name {
      margin-bottom: 5px;
    }
    .unit-name {
      color: var($--font-4);
      padding: 0 20px;
      display: block;
      // align-items: center;
      width: 100%;
      box-sizing: border-box;
      @include body2;
      .mat-icon {
        margin-right: 0;
        height: auto;
        width: auto;
      }
      .unit-name_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
      .unit-name_expand {
        position: absolute;
        right: 20px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .mat-mdc-list-item {
    @include body3;
    &:hover {
      background-color: var($--primary-6);
    }
  }
  .mat-mdc-menu-item{
    &:hover {
      background-color: var($--primary-6);
    }
  }
}
.sidenav-header {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  .mat-icon {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
  }
}

.divider-container {
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.mat-mdc-nav-list {
  padding: 0;
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mdc-list-item__content {
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      .mat-icon-container {
        &.mat-icon-container-no-background {
          background-color: transparent;
        }
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: var($--primary-6);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        .mat-icon {
          color: var($--primary-1);
          font-size: 20px;
          width: 20px;
          height: 20px;
          //margin-right: 25px;
        }
      }
    }
  }
}

.no-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  padding: 50px 0 100px 0;
  span {
    text-align: center;
  }
  img {
    width: 150px;
    margin-left: 8px;
  }
}

body .mat-calendar-controls {
  margin: 0;
}


@-moz-keyframes rotate { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes rotate { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading {
  .loadable * {
    border-color: transparent!important;
    color: transparent!important;
    background-color: transparent!important;
    box-shadow: none!important;
  }
  .loadable {
    position: relative;
    color: transparent!important;
    border-color: transparent!important;
    background-color: transparent!important;
    box-shadow: none!important;
    pointer-events: none;
    user-select: none;
    &:after {
      position: absolute;
      background: linear-gradient(90deg, var($--gray-7), var($--gray-8), var($--gray-9), var($--gray-8), var($--gray-7));
      //background-color: var($--gray-4);
      background-size: 400% 400%;
      animation: gradient 7s ease infinite;
      border-radius: 20px;
      content: " ";
      top: 10%;
      left: 0;
      //width: 80%;
      width: 100%;
      //height: 80%;
      height: 90%;
      transition: 2s linear all;
      min-height: unset;
    }

    .mat-badge {display: none}
  }
}

.message-folder-list {
  &.mat-mdc-menu-panel {
    border-radius: 24px;
    min-width: 200px;
  }

  .mat-mdc-menu-content {
    grid-area: folder-list;
    height: 100%;
    padding-right: 0;
    border-top: 1px solid var($--gray-8);
    border-right: 1px solid var($--gray-8);
    box-sizing: border-box;

    .folder {
      @include h9;
      @include accent-font-3;
      cursor: pointer;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      padding: 16px 16px 16px 30px;
      background: linear-gradient(to left, transparent 50%, var($--primary-6) 50%) right;
      background-size: 200%;
      transition: background-position 0.2s ease;
      &.active {
        background-position: left;
        .folder-name {
          color: var($--primary-1);
        }
      }
      .pq-badge-flat {
        border-radius: 29px;
        font-weight: 600;
      }
      .folder-name {
        display: flex;
      }
      .mat-icon {
        width: 22px;
        height: 22px;
        margin-right: 14px;
      }
    }
  }
}

.mat-icon {
  &.mat-icon-20 {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}

.hidden {
  display: none !important;
}

.margin-bottom {
  margin-bottom: 1.34375em;

  &.mat-divider {
    padding-bottom: 1.34375em; // margin 0 overrides in mat-dividor
  }
}

@media screen and (max-width: 840px) {
  .mat-sidenav-container {
    margin-top: 56px;
  }
  .main-container {
    .router-container {
      &.router-container__drop-padding-840 {
        padding: 0;
      }
    }
  }
}

@media print {
  app-toolbar,
  app-footer{
    display: none;
  }
  .no-print {
    display: none;
  }
}
