/* For platform-specific styles 

Used to override styles on specific platform

Classes available
.platform-ios - For iOS devices

.platform-android - For android devices

.platform-mobile - For iOS or android devices

.platform-web - For web only

*/

.platform-ios {
    padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom,
            0) env(safe-area-inset-left, 0);
}


.platform-mobile  {
    ::-webkit-scrollbar {
        display: none;
    }

    app-download-app {
        display: none;
    }

    app-toolbar {
        top: 0;
        padding-top: env(safe-area-inset-top, 0);
        background-color: #ffffff;
    }

    .cdk-overlay-container {
        .cdk-global-overlay-wrapper {
            padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom,
                0) env(safe-area-inset-left, 0);
            box-sizing: border-box;

            .mat-mdc-dialog-content.frame-container {
                width: 100%;
                box-sizing: border-box;
            }

            .mat-mdc-dialog-content {
                box-sizing: border-box;
            }
        }
    }

    .sidenav-container-profile {
        padding-top: env(safe-area-inset-top, 0);
    }

    .notice-container--property-manager {
        display: none;
    }

    .toast-container {
        .ngx-toastr {
            margin: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom,
            0) env(safe-area-inset-left, 0);
            box-sizing: border-box;
        }
    }

    [printSectionId][ngxPrint] {
        display: none;
    }
    
}