@import "src/styles/mixins";

.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 25px 40px rgba(21, 21, 56, 0.02);
}

//mat-divider
.mat-mdc-card {
  box-sizing: border-box;
  .mat-divider {
    &.mat-divider-horizontal {
      position: relative;
    }
  }
}

.main-container .mat-mdc-card, .mat-mdc-card {
  padding: 30px;
  border-radius: 20px;

  &.disabled {
    background-color: var($--gray-8);
  }
  &.disabled-primary {
    background-color: var($--primary-6);
  }
  &.mat-card-suppress {
    padding: 0!important;
    background: transparent!important;
    border-radius: 0!important;
    box-shadow: none!important;
  }
  &.mat-card-success {
    border: 1px solid var($--accent-success-3);
    background-color: var($--accent-success-4);
  }
  &.mat-card-warning {
    //border: 1px solid var($--accent-warning-1);
    color: var($--accent-warning-2);
    background-color: var($--accent-warning-5);
  }
  &.mat-card-primary {
    border: 1px solid var($--primary-5);
    background-color: var($--primary-6);
  }
  &.mat-card-stroked {
    border: 1px solid var($--gray-7);
    background-color: transparent;
  }
  &.mat-card-stroked-primary {
    border: 1px solid var($--primary-1);
    background-color: var($--background-4);
  }
  &.mat-card-padding-small {
    padding: 15px 18px;
  }
}

mat-dialog-container.mat-mdc-dialog-container {
  .mat-mdc-dialog-content {
    .mat-mdc-card {
      border-radius: 12px;
    }
  }
}
