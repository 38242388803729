@import "src/styles/mixins";

html {
  --mat-dialog-container-max-width: 100vw;
  --mat-dialog-container-small-max-width: 100vw;
}

mat-dialog-container.mat-mdc-dialog-container {
    //  @include h7;
    --mdc-dialog-subhead-size: 18px;
    --mdc-dialog-subhead-line-height: 20px;
    //  @include body3
    --mdc-dialog-supporting-text-font: 'Mulish';
    --mdc-dialog-supporting-text-size: 14px;
    --mdc-dialog-supporting-text-line-height: 22px;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: 0.035em;
    --mdc-dialog-supporting-text-color: var(--font-2);

    --mdc-dialog-container-shape: 20px;

    --mdc-icon-button-icon-color: var($--accent-danger-1);

    .mat-mdc-dialog-title {
      @include h7;
      padding: 0 20px 20px
    }

    .title-row {
      padding: 10px 20px 10px 20px;

      .mat-dialog-close {
        @include forms-button-large;
        color: var($--accent-danger-1);
        .mat-icon {
          margin-right: 0;
        }
      }
    }
}

@media screen and (max-width: 840px) {
  .cdk-global-overlay-wrapper {
    display: block;
    .cdk-overlay-pane {
      height: 100%;
      .dialog-message-new-message {
        width: 100%;
        .mat-mdc-dialog-container {
          .mat-mdc-dialog-content {
            max-height: max-content;
          }
        }
      }
      .mat-mdc-dialog-container {
        --mdc-dialog-container-shape: 0;
        .title-row {
          padding: 14px 20px 14px 20px;
        }
        .title-row:not(.new-amenity-booking-title) {
          //make dialog title row sticky
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        .mat-mdc-dialog-content {
          &.mat-dialog-content-no-padding {
            padding: 0;
          }
          max-height: max-content;
          padding: 20px;
        }
      }
    }
  }
  .dialog-message-new-message {
    width: 100%;
  }
  .mat-mdc-dialog-container {
    overflow-x: hidden !important;
  }
}






/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container.mat-mdc-dialog-container{
  // @include body3;
  //max-width: 568px;

  // .title-row {
  //   padding: 35px 40px 24px 40px;
  // }
  .mat-mdc-dialog-content {
    /*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    &.mat-dialog-content-no-padding {
      padding: 0;
    }
    padding: 0 34px 40px 40px;
    //margin: 0 0 40px 0;
    margin: 0;
  }
  .mat-mdc-dialog-content + .mat-mdc-dialog-actions {
    padding: 24px 40px 35px 40px;
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-actions.mat-mdc-dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  button:not(.mat-mdc-icon-button) {
    padding: 0 30px;
    &.mat-mdc-unelevated-button.mat-primary {
      min-width: 140px;
    }
    &.mat-mdc-unelevated-button.mat-warn {
      min-width: 140px;
    }
  }
}

.dialog-message-new-message {
  min-width: 65vw;
}
.dialog-amenity-new-amenity {
  /*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container.mat-mdc-dialog-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .title-row {
      padding: 0;
    }
  }
}
