@import "src/styles/mixins";

.mat-mdc-slider {
  margin-top: 12px;
  .mdc-slider__track {
    width: calc(100% + 12px);
    margin-left: -6px;
    background-color: var($--gray-8);
  }

  .mdc-slider__track--active {
    width: calc(100% - 12px);
    margin-left: 6px;
  }

  // .mdc-slider__thumb-knob {
  //   border: 6px solid;
  //   height: 12px;
  //   width: 12px;
  //   box-shadow: 0px 3px 13px rgba(0, 71, 80, 0.28);
  //   border-color: var($--background-4);
  // }

  .mdc-slider__value-indicator-container {
    bottom: 34px;
  }
  .mdc-slider__value-indicator {
    transform: scale(1);;
  }

  .mdc-slider__value-indicator-text {
    &::before {
      content: '$';
    }
    // background: var($--primary-6);
    padding: 2px 6px;
    border-radius: 5px;
    @include body5-medium;
  }
  .mat-mdc-focus-indicator {
    //display: none;
    .mat-mdc-slider-focus-ripple {
      display: none;
    }
    .mat-mdc-slider-hover-ripple {
      display: none;
    }

  }
  .mdc-slider__value-indicator {
    &::before {
      display: none;
    }
  }
}