@import "../mixins";

.toast-container {
  .ngx-toastr {
    color: unset;
    background-image: none;
    width: 420px;
  }
  .ngx-toastr {
    background-color: var($--background-4);
    border-left: 5px solid var($--primary-1);
    border-radius: 5px;
    pointer-events: auto;
    padding: 15px;
    box-shadow: 0px 15px 35px rgba(24, 39, 75, 0.05);
    &:hover {
      box-shadow: 0px 15px 35px rgba(24, 39, 75, 0.1);
    }
  }
  .toast-close-button {
    width: 20px;
    height: 20px;
    color: var($--primary-1);

    span {
      display: none;
    }
  }
  .toast-title {
    @include h7-bold;
  }
  .toast-message {
    @include body3;
    @include accent-font-3;
    a {
      @include accent-primary-1;
      &:hover {
        @include accent-primary-2;
      }
    }
  }
  .toast-success {
    border-left: 5px solid var($--accent-success-1);
    color: var($--accent-success-2);
    .toast-close-button {
      color: var($--accent-success-2);
    }
  }
  .toast-error {
    border-left: 5px solid var($--accent-danger-1);
    color: var($--accent-danger-2);
    .toast-close-button {
      color: var($--accent-danger-2);
    }
  }
  .toast-info {
    border-left: 5px solid var($--accent-info-1);
    color: var($--accent-info-2);
    .toast-close-button {
      color: var($--accent-info-2);
    }
  }
  .toast-warning {
    border-left: 5px solid var($--accent-warning-1);
    color: var($--accent-warning-2);
    .toast-close-button {
      color: var($--accent-warning-2);
    }
  }
}


@media screen and (max-width: 480px){

  .toast-container {
    .ngx-toastr {
      color: unset;
      background-image: none;
      width: 100%;
    }
  }
  .toast-top-right {
    top: 0;
    right: 0;
  }
}

