@import "src/styles/mixins";
.mat-mdc-menu-panel {
  &.pq-menu {
    margin-top: 18px;
    position: relative;
    overflow: unset;
    border-radius: 15px;
    box-shadow: 0px 10px 85px -15px rgba(227, 230, 236, 0.95);
  }
  &.pq-menu-center {
    &:not(.sidenav-profile-unit-menu) {
      margin-left: calc(-50% + 36px);
    }
  }
  &.pq-menu-right {
    .triangle {
      left: unset;
      right: 0;
    }
  }
  &.main-navigation-menu {
    .mat-mdc-menu-content {
      padding: 10px;
      .mat-mdc-menu-item {
        padding: 10px;
        height: unset;
        line-height: unset;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        color: var($--font-3);
        border-radius: 10px;
        min-width: 250px;
        @include body3;
        &.active,
        &:hover {
          color: var($--primary-1);
          background-color: var($--primary-6);
        }
        // span {
        //   flex: 1;
        // }
        .mat-mdc-menu-item-text {
          margin-right: auto;
        }
        .mat-icon-container {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var($--primary-6);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        .mat-icon {
          color: var($--primary-1);
          margin-right: 0;
          &.checkmark_circle {
            margin-left: auto;
          }
        }
      }
    }
  }
  &.avatar__menu-container {
    padding: 15px;
    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      width: 175px;
      overflow: unset;
    }
  }
  .mat-mdc-menu-item-submenu-trigger {
    padding-right: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
    .mat-mdc-menu-submenu-icon {
      display: none;
    }
  }
  .mat-mdc-menu-item {
    .mat-ripple-element {
      opacity: 0.1;
      background-color: var($--primary-1);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
    &.mat-menu-item-highlighted {
      background-color: var($--primary-6);
    }
    &.unit-name {
      padding: 0 20px;

      .mat-icon {
        line-height: 48px;
      }
    }
  }
  .triangle{
    position: absolute;
    width: 60px;
    height: 20px;
    overflow: hidden;
    top: -20px;
    left: calc(50% - 30px);
    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background: var($--background-4);
      transform: rotate(45deg); /* Prefixes... */

      box-shadow: 0px 26px 85px -15px rgba(227, 230, 236, 0.95);
      top: 50%;
      left: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .mat-mdc-menu-panel {
    &.sidenav-profile-unit-menu {
      //right: -355px;
      right: calc(-100% + 13px);
      margin-left: 0;
      top: 40px;

    }
  }

}

.mat-mdc-menu-item .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}