@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600&family=Work+Sans:wght@400;500;600&display=swap');
// @import "~@angular/material/theming";
@import "mixins";

$custom-typography-config: mat.m2-define-typography-config(
  $headline-5:    mat.m2-define-typography-level(64px, 64px, 500, Work Sans, 0.015em), //h1
  $headline-6:    mat.m2-define-typography-level(48px, 48px, 500, Work Sans, 0.015em), //h2
  $subtitle-1:    mat.m2-define-typography-level(32px, 32px, 500, Work Sans, 0.015em), //h3
  $subtitle-2:    mat.m2-define-typography-level(14px, 24px, 400, Mulish, 0.035em),    //h4
  $body-1:        mat.m2-define-typography-level(14px, 24px, 400, Mulish, 0.035em),    //body 1
  $body-2:        mat.m2-define-typography-level(14px, 24px, 400, Mulish, 0.035em),    //body 2
  $button:        mat.m2-define-typography-level(14px, 16px, 600, Work Sans, 0.015em),
);
//   $body-2:        mat.define-typography-level(16px, 24px, 400, Mulish, 0.035em), //body 2

//$toolbar-typography-config: mat.define-typography-config(
//  $headline:      mat.define-typography-level(14px, 16px, 500, Work Sans), //h1
//  $title:         mat.define-typography-level(14px, 16px, 500, Work Sans), //h2
//  $subheading-2:  mat.define-typography-level(14px, 16px, 500, Work Sans), //h3
//  $subheading-1:  mat.define-typography-level(14px, 16px, 500, Work Sans), //h4
//);
$toolbar-typography-config: mat.m2-define-typography-config(
  $headline-5:    mat.m2-define-typography-level(14px, 16px, 500, Work Sans, 0.015em), //h1
  $headline-6:    mat.m2-define-typography-level(14px, 16px, 500, Work Sans, 0.015em), //h2
  $subtitle-1:    mat.m2-define-typography-level(14px, 16px, 500, Work Sans, 0.015em), //h3
  $body-1:        mat.m2-define-typography-level(14px, 16px, 500, Work Sans, 0.015em), //h4
);

$table-typography-config: mat.m2-define-typography-config(
  $caption: mat.m2-define-typography-level(12px, 18px, 600, Work Sans, 0.015em), //h1
  $body-1:  mat.m2-define-typography-level(12px, 20px, 600, Mulish, 0.035em), //h2
  $body-2:  mat.m2-define-typography-level(12px, 20px, 600, Mulish, 0.035em), //h3
);

.forms-label {
  @include forms-label;
}
.forms-input {
  @include forms-input;
}

.forms-button-large {
  @include forms-button-large;
}

.body2 {
  @include body2;
}

.body3 {
  @include body3;
}

.body4 {
  @include body4;
}

.body1-medium {
  @include body1-medium;
}

.body2-medium {
  @include body2-medium;
}

.body3-medium {
  @include body3-medium;
}

.body4-medium {
  @include body4-medium;
}

.body5-medium {
  @include body5-medium;
}

.h2 {
  @include h2;
}
.h2-bold {
  @include h2-bold;
}

.h3 {
  @include h3;
}
.h3-bold {
  @include h3-bold;
}

.h4 {
  @include h4;
}
.h4-bold {
  @include h4-bold;
}

.h5-bold {
  @include h5-bold;
}

.h5 {
  @include h5;
}
.h6 {
  @include h6;
}
.h6-bold {
  @include h6-bold;
}
.h7 {
  @include h7;
}
.h7-bold {
  @include h7-bold;
}
.h8 {
  @include h8;
}
.h8-bold {
  @include h8-bold;
}
.h9 {
  @include h9;
}
.h9-bold {
  @include h9-bold;
}

.text-overflow-hidden {
  @include text-overflow-hidden;
}

.accent-font-1 {
  @include accent-font-1;
}

.accent-font-2 {
  @include accent-font-2;
}

.accent-font-3 {
  @include accent-font-3;
}

.accent-font-4 {
  @include accent-font-4;
}
.accent-font-white {
  @include accent-font-white;
}
.accent-secondary {
  color: var($--secondary-2);
}

.accent-primary-1 {
  @include accent-primary-1;
}
.accent-primary-2 {
  @include accent-primary-2;
}
.accent-primary-3 {
  @include accent-primary-3;
}
.accent-primary-4 {
  @include accent-primary-4;
}
.accent-primary-5 {
  @include accent-primary-5;
}
.accent-primary-6 {
  @include accent-primary-6;
}

.accent-font-success-1 {
  @include accent-font-success-1;
}
.accent-font-success-2 {
  @include accent-font-success-2;
}
.accent-font-success-3 {
  @include accent-font-success-3;
}
.accent-font-success-4 {
  @include accent-font-success-4;
}


.accent-warning-1 {
  color: var($--accent-warning-1);
}
.accent-danger-1 {
  color: var($--accent-danger-1);
}
.accent-info-1 {
  color: var($--accent-info-1);
}

