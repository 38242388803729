@use 'sass:map';
@use './node_modules/@angular/material' as mat;

@mixin color($success, $warning, $danger, $info){
  .pq-badge {
    &.success {
      color: mat.m2-get-color-from-palette($success, 700);
      background-color: mat.m2-get-color-from-palette($success, 50);
    }
    &.warning {
      color: mat.m2-get-color-from-palette($warning, 800);
      background-color: mat.m2-get-color-from-palette($warning, 50);
    }
    &.danger {
      color: mat.m2-get-color-from-palette($danger, 700);
      background-color: mat.m2-get-color-from-palette($danger, 50);
    }
    &.info {
      color: mat.m2-get-color-from-palette($info, 700);
      background-color: mat.m2-get-color-from-palette($info, 50);
    }
  }
  .pq-badge-flat {
    &.success {
      color: #ffffff;
      background-color: mat.m2-get-color-from-palette($success, 300);;
    }
    &.warning {
      color: #ffffff;
      background-color: mat.m2-get-color-from-palette($warning, 300);
    }
    &.danger {
      color: #ffffff;
      background-color: mat.m2-get-color-from-palette($danger, 300);
    }
    &.info {
      color: #ffffff;
      background-color: mat.m2-get-color-from-palette($info, 300);
    }
  }
}

@mixin theme($success-palette, $warning-palette, $danger-palette, $info-palette) {
  @include color($success-palette, $warning-palette, $danger-palette, $info-palette);
}