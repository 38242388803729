@use '@angular/material' as mat;
@import "/src/styles/mixins";

.mat-mdc-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
  --mdc-text-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: var(--mdc-text-button-container-shape);
  --mdc-filled-button-container-shape: var(--mdc-text-button-container-shape);
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-disabled {
  .mat-mdc-progress-spinner {
    margin-right: 10px;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-button-shadow {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;

  cursor: pointer;

  @include button-font-medium;
}

.mat-mdc-button {
  line-height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    align-items: center;
  }
  &.mat-mdc-button__wrapper-space-between {
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      justify-content: space-between;
    }
  }
  &.illustrated-button {
    width: 50px;
    height: 50px;
    padding: 0;
    min-width: auto;
    background: var($--primary-5);
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      line-height: 32px;
    }
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-button-base,
.mat-button-shadow {
  padding: 0;
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-shadow-default-padding {
    padding: 0 16px;
  }
  &.mat-mdc-icon-button {
    &.mat-stroked-icon-button {
      border: 1px solid var($--primary-5);
      background: var($--primary-6);
      border-radius: 12px;
    }
    &.mat-stroked-icon-button-50 {
      height: 50px;
      width: 50px;
    }
  }
  &.mat-mdc-unelevated-button,
  &.mat-mdc-outlined-button{
    padding: 8px 30px;
    --mdc-text-button-container-shape: 8px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-size-small{
    padding: 11px 20px;
    border-radius: 8px;
    @include button-font-small;

    .mat-icon {
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-size-medium{
    padding: 14px 20px;
    border-radius: 10px;
    @include button-font-medium;

    .mat-icon {
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
}

.mat-mdc-button {
  &.illustrated-button {
    width: 50px;
    height: 50px;
    padding: 0;
    min-width: auto;
    background: rgba(55, 178, 194, 0.09);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      display: flex;
      width: 32px;
      height: auto;
    }
  }
}

.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--font-white);
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  &.mat-mdc-button-base {
    height: 50px;
  }
  &.mat-mdc-button-base.mat-button-size-small {
    height: 36px;
  }
}

.mdc-button__label {
  display: inline-flex;
  align-items: center;
  mat-spinner {
    display: block;
    margin: 10px;
  }
}

.mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label {
  z-index: 0 !important; //button label was showing up in front of nav tabs
}

.mat-mdc-unelevated-button>.mat-icon { //for floating new message and new request buttons
  height: 24px !important;
  width: 24px !important;
}
