@import "../mixins";
label + .ng-select {
  margin-top: 10px;
}
.ng-select {
  @include forms-input;
  padding-bottom: 0;
  &.size-small {
    .ng-select-container {
      padding: 8px;
    }
  }
  &.ng-select-focused {
    .ng-select-container {
      border-color: var($--primary-1);
      .ng-value-container {
        .ng-placeholder {
          transform: unset;
          @include accent-font-4;
        }
      }
      .ng-arrow-wrapper {
        .ng-arrow {
          color: var($--primary-1);
        }
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      .ng-clear-wrapper {
        bottom: 0;
      }
      .ng-arrow-wrapper {
        bottom: 0;
        .ng-arrow {
          margin-bottom: 3px;
        }
      }
      .ng-value-container {
        .ng-input {
          left: 17px;
        }
      }
    }
  }
  &.ng-select-filtered {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          transform: translateY(-2em);
        }
      }
    }
  }
  //validation styles
  //&.mat-focused {
  //  input,
  //  .mat-form-field-prefix,
  //  .mat-form-field-infix + .mat-form-field-suffix {
  //    border-color: var($--primary-1);
  //  }
  //}
  &.ng-touched {
    &.ng-valid {
      .ng-select-container {
        border-color: var($--accent-success-1);
      }
    }
    &.ng-invalid {
      .ng-select-container {
        border-color: var($--accent-danger-1);
      }
    }
  }
  &.ng-select-disabled {
    .ng-select-container {
      background-color: var($--gray-8);
    }
  }
  //-----------------------------
  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }
  .ng-select-container {
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid var($--gray-7);
    border-radius: 8px;
    min-height: unset;

    &:after {
      display: none;
    }
    .ng-value-container {
      position: relative;
      border: 0;
      padding: 0;
      input {
        @include forms-input;
      }
      .ng-input {
        bottom: unset;
        left: 17px;
      }
      .ng-placeholder {
        position: relative;
        transform-origin: unset;
        transition: unset;
        @include accent-font-4;
      }
    }
  }
}
.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-selected {
        @include accent-primary-1;
        background-color: var($--background-2);
      }
      &.ng-option-marked {
        @include accent-primary-1;
        background-color: var($--background-2);
      }
      @include accent-font-3;
    }
  }
}
